import { Layout } from '@components/layout';
import { ForgotPasswordTitle, ForgotPasswordForm } from '@components/standard';
import type { IPage, ILocation } from '@standardTypes';
import React from 'react';

interface ForgotPasswordPageProps {
  pageContext: {
    page: IPage;
  };
  location: ILocation;
}

const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = ({
  pageContext
}) => {
  const { page } = pageContext;

  return (
    <Layout {...page}>
      <ForgotPasswordTitle />
      <ForgotPasswordForm />
    </Layout>
  );
};

export default ForgotPasswordPage;
